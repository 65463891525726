<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div> <h1> <strong>FICHE STATISTIQUE</strong> </h1>  </div>
        </b-col>
        <b-col md="12">
          <div>
            <small>(Agence de voyage, Tours Operator, Service de Réservation)</small>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Spacer -->
    <hr class="invoice-spacing">

    <b-card-body class="invoice-padding pt-0">
      <div class="d-flex justify-content-center text-center mb-1">
        <b-row>
          <h6 class="mb-25">
            {{ validOutput ? validOutput.mois : '' }}  {{ validOutput ? validOutput.annee : '' }}
          </h6>
        </b-row>
      </div>
    </b-card-body>

    <b-card-body class=" nopadding">
      <div class="pl-1 pr-1">
        <b-row>
          <b-col md="2" />
          <b-col
            md="4"
            class=""
          >
            Département: <strong> {{ validOutput.departement }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Commune: <strong> {{ validOutput.departement }} </strong>
          </b-col>
          <b-col md="2" />
          <b-col md="2" />
          <b-col md="4">
            Arrondissement: <strong> {{ validOutput.arrondissement }} </strong>
          </b-col>
          <b-col
            md="4"
            class=" text-right"
          >
            Quartier/Village: <strong> {{ validOutput.quartier }} </strong>
          </b-col>
          <b-col md="2" />
        </b-row>
      </div>
    </b-card-body>

    <!-- renseignement generaux -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> I- RENSEIGNEMENTS GÉNÉRAUX </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Nom de l'établissement: <strong> {{ validOutput.nomEtablissement }} </strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Numéro IFU: <strong> {{ validOutput.numIFU }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Nom du promoteur: <strong> {{ validOutput.nomPromoteur }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            Prénom(s) du promoteur: <strong> {{ validOutput.prenomsPromoteur }} </strong>
          </b-col>
          <b-col
            md="4"
            class="mb-1 text-right"
          >
            Nationalité du promoteur: <strong> {{ validOutput.nationalitePromoteur }} </strong>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Adresse complète de l'établissement: <strong> {{ validOutput.adresseComplete }} </strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Type de l'établissement: <strong> {{ validOutput.typeEtablissement }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            Téléphone:
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone1 }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone2 }} </strong>
          </b-col>
          <b-col
            md="3"
            class="mb-1"
          >
            <strong> {{ validOutput.telephone3 }} </strong>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Détenez-vous un agrément du Ministère en charge du tourisme ?
            <strong>
              {{ validOutput.agrementMinistere }} {{ validOutput.agrementMinistere == 'oui' ? ', '+validOutput.referenceAgrement : '' }}
            </strong>
          </b-col>
          <!-- <b-col
            md="6"
            class="mb-1 text-right"
          >
            Réservé à l'administration: <strong> {{ validOutput.reserveAdministration }} </strong>
          </b-col> -->
        </b-row>
      </div>
    </b-card-body>

    <!-- emplois -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> II- EMPLOIS </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <!-- employés salariés -->
      <div class="table-responsive text-center">
        <table class="table table-bordered">
          <thead class="bg-primary text-white">
            <tr>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                rowspan="2"
              >
                Nombre d'employés salariés
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Femmes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Total
              </th>
            </tr>
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nationaux
              </th>
              <td>{{ validOutput.employesSalaries.hommeTempspleinNationaux }}</td>
              <td>{{ validOutput.employesSalaries.hommeTempspartielNationaux }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspleinNationaux }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspartielNationaux }}</td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinNationaux
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielNationaux
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Étrangers
              </th>
              <td>{{ validOutput.employesSalaries.hommeTempspleinEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.hommeTempspartielEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspleinEtrangers }}</td>
              <td>{{ validOutput.employesSalaries.femmeTempspartielEtrangers }}</td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinEtrangers +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielEtrangers +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Total
              </th>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.hommeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.hommeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.femmeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.femmeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspleinNationaux +
                    validOutput.employesSalaries.femmeTempspleinNationaux +
                    validOutput.employesSalaries.hommeTempspleinEtrangers +
                    validOutput.employesSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesSalaries.hommeTempspartielNationaux +
                    validOutput.employesSalaries.femmeTempspartielNationaux +
                    validOutput.employesSalaries.hommeTempspartielEtrangers +
                    validOutput.employesSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- nouveeaux employés du mois  -->
      <div class="table-responsive text-center mt-3">
        <table class="table table-bordered">
          <thead class="text-white tableHeadFontSize">
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Salarié recrutés au cours du mois
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
              >
                Femmes
              </th>
              <th
                scope="col"
                class="bg-primary"
                tableHeadFontSize
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nombre
              </th>
              <td>{{ validOutput.nouveauEmplois.hommes }}</td>
              <td>{{ validOutput.nouveauEmplois.femmes }}</td>
              <td>
                {{
                  validOutput.nouveauEmplois.hommes + validOutput.nouveauEmplois.femmes
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- </b-card-body> -->
      <div class="html2pdf__page-break" /> <!-- break for pdf printer -->
      <!-- <b-card-body class="invoice-padding"> -->
      <!-- employés non salariés -->
      <div class="table-responsive text-center mt-3">
        <table class="table table-bordered">
          <thead class="bg-primary text-white tableHeadFontSize">
            <tr>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                rowspan="2"
              >
                Nombre d'employés non salariés
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Hommes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Femmes
              </th>
              <th
                class="bg-primary tableHeadFontSize"
                scope="col"
                colspan="2"
              >
                Total
              </th>
            </tr>
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize tableHeadFontSize"
              >
                Temps partiel
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps plein
              </th>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              >
                Temps partiel
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Nationaux
              </th>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspleinNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspartielNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspleinNationaux }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspartielNationaux }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinNationaux
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielNationaux
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Étrangers
              </th>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspleinEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.hommeTempspartielEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspleinEtrangers }}
              </td>
              <td>
                {{ validOutput.employesNonSalaries.femmeTempspartielEtrangers }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinEtrangers +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielEtrangers +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                Total
              </th>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.hommeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.hommeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.femmeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.femmeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspleinNationaux +
                    validOutput.employesNonSalaries.femmeTempspleinNationaux +
                    validOutput.employesNonSalaries.hommeTempspleinEtrangers +
                    validOutput.employesNonSalaries.femmeTempspleinEtrangers
                }}
              </td>
              <td>
                {{
                  validOutput.employesNonSalaries.hommeTempspartielNationaux +
                    validOutput.employesNonSalaries.femmeTempspartielNationaux +
                    validOutput.employesNonSalaries.hommeTempspartielEtrangers +
                    validOutput.employesNonSalaries.femmeTempspartielEtrangers
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>

    <!-- renseignment exploitation du mois -->
    <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> III- RENSEIGNEMENTS SUR L'EXPLOITATION DU MOIS</strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body>
      <!-- voyages organisés & croisières tout compris -->
      <b-row><b-col
        md="12"
        class="text-center mt-2"
      >
        <strong>
          III.1 VOYAGES ORGANISÉS ET CROISIÈRES TOUT COMPRIS
        </strong>
      </b-col></b-row>
      <b-row>
        <!-- Partie gauche -->
        <b-col md="6">
          <div class="table-responsive text-center mt-1">
            <table class="table table-striped table-bordered">
              <thead class="text-white">
                <tr>
                  <th
                    scope="col"
                    class="bg-primary"
                  >
                    Voyage Internes
                  </th>
                  <th class="bg-primary">
                    Nombres
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    Voyage d'entrée sans frais
                  </th>
                  <td>{{ validOutput.voyagesInternes.sansFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Voyage d'entrée avec frais
                  </th>
                  <td>{{ validOutput.voyagesInternes.avecFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Total
                  </th>
                  <td>
                    {{
                      validOutput.voyagesInternes.sansFrais +
                        validOutput.voyagesInternes.avecFrais
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <!-- Partie droite -->
        <b-col md="6">
          <div class="table-responsive text-center mt-1">
            <table class="table table-striped table-bordered">
              <thead class="text-white">
                <tr>
                  <th
                    scope="col"
                    class="bg-primary tableHeadFontSize"
                  >
                    Voyage Internationaux
                  </th>
                  <th class="bg-primary tableHeadFontSize">
                    Nombres
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    Voyage d'entrée sans frais
                  </th>
                  <td>{{ validOutput.voyagesInternationaux.entreeSansFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Voyage d'entrée avec frais
                  </th>
                  <td>{{ validOutput.voyagesInternationaux.entreeAvecFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Voyage de sortie sans frais
                  </th>
                  <td>{{ validOutput.voyagesInternationaux.sortieSansFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Voyage de sortie avec frais
                  </th>
                  <td>{{ validOutput.voyagesInternationaux.sortieAvecFrais }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Total
                  </th>
                  <td>
                    {{
                      validOutput.voyagesInternationaux.entreeSansFrais +
                        validOutput.voyagesInternationaux.entreeAvecFrais +
                        validOutput.voyagesInternationaux.sortieSansFrais +
                        validOutput.voyagesInternationaux.sortieAvecFrais
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>

      <!-- recettes brutes -->
      <b-row><b-col
        md="12"
        class="text-center mt-2 "
      >
        <strong>
          III.2 RECETTE BRUTES
        </strong>
      </b-col></b-row>
      <div class="table-responsive text-center mt-1">
        <table class="table table-striped table-bordered">
          <thead class="text-white">
            <tr>
              <th
                scope="col"
                class="bg-primary"
              />
              <th class="bg-primary tableHeadFontSize">
                Montants
              </th>
              <th class="bg-primary tableHeadFontSize">
                Pourcentages
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Billetterie
              </th>
              <td>{{ validOutput.recettesBrutes.billetterie }}</td>
              <td v-if="validOutput.recettesBrutes.billetterie > 0">
                {{
                  (validOutput.recettesBrutes.billetterie * 100) /
                    (validOutput.recettesBrutes.billetterie +
                      validOutput.recettesBrutes.voyagesOrganises +
                      validOutput.recettesBrutes.croisieresToutCompris +
                      validOutput.recettesBrutes.autresProduits)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Voyages Organisés
              </th>
              <td>{{ validOutput.recettesBrutes.billetterie }}</td>
              <td v-if="validOutput.recettesBrutes.voyagesOrganises > 0">
                {{
                  (validOutput.recettesBrutes.voyagesOrganises * 100) /
                    (validOutput.recettesBrutes.billetterie +
                      validOutput.recettesBrutes.voyagesOrganises +
                      validOutput.recettesBrutes.croisieresToutCompris +
                      validOutput.recettesBrutes.autresProduits)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Croisières Tout Compris
              </th>
              <td>{{ validOutput.recettesBrutes.billetterie }}</td>
              <td v-if="validOutput.recettesBrutes.croisieresToutCompris > 0">
                {{
                  (validOutput.recettesBrutes.croisieresToutCompris * 100) /
                    (validOutput.recettesBrutes.billetterie +
                      validOutput.recettesBrutes.voyagesOrganises +
                      validOutput.recettesBrutes.croisieresToutCompris +
                      validOutput.recettesBrutes.autresProduits)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Autres Produits
              </th>
              <td>{{ validOutput.recettesBrutes.autresProduits }}</td>
              <td v-if="validOutput.recettesBrutes.autresProduits > 0">
                {{
                  (validOutput.recettesBrutes.autresProduits * 100) /
                    (validOutput.recettesBrutes.billetterie +
                      validOutput.recettesBrutes.voyagesOrganises +
                      validOutput.recettesBrutes.croisieresToutCompris +
                      validOutput.recettesBrutes.autresProduits)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Recettes Brutes Totales
              </th>
              <td>
                {{
                  validOutput.recettesBrutes.billetterie +
                    validOutput.recettesBrutes.voyagesOrganises +
                    validOutput.recettesBrutes.croisieresToutCompris +
                    validOutput.recettesBrutes.autresProduits
                }}
              </td>
              <td
                v-if="
                  validOutput.recettesBrutes.billetterie > 0 ||
                    validOutput.recettesBrutes.voyagesOrganises > 0 ||
                    validOutput.recettesBrutes.croisieresToutCompris > 0 ||
                    validOutput.recettesBrutes.autresProduits
                "
              >
                100 %
              </td>
              <td v-else>
                0 %
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="html2pdf__page-break" /> <!-- break for pdf printer -->

      <!-- commission total réalisées-->
      <b-row><b-col
        md="12"
        class="text-center mt-2"
      >
        <strong>
          III.3 COMMISSION TOTALE REALISEE
        </strong>
      </b-col></b-row>
      <div class="table-responsive text-center mt-1">
        <table class="table table-striped table-bordered">
          <thead class="text-white">
            <tr>
              <th
                scope="col"
                class="bg-primary tableHeadFontSize"
              />
              <th class="bg-primary tableHeadFontSize">
                Montants
              </th>
              <th class="bg-primary tableHeadFontSize">
                Pourcentages
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Billetterie
              </th>
              <td>{{ validOutput.commissions.billetterie }}</td>
              <td v-if="validOutput.commissions.billetterie > 0">
                {{
                  (validOutput.commissions.billetterie * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Voyages Organisés
              </th>
              <td>{{ validOutput.commissions.voyagesOrganises }}</td>
              <td v-if="validOutput.commissions.voyagesOrganises > 0">
                {{
                  (validOutput.commissions.voyagesOrganises * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Croisières Tout Compris
              </th>
              <td>{{ validOutput.commissions.croisieresToutCompris }}</td>
              <td v-if="validOutput.commissions.croisieresToutCompris > 0">
                {{
                  (validOutput.commissions.croisieresToutCompris * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Hébergements (Prière d'exculre les commissions sur des
                hébergements faisant partie de voyages organisés ou de
                croisières)
              </th>
              <td>{{ validOutput.commissions.hebergement }}</td>
              <td v-if="validOutput.commissions.hebergement > 0">
                {{
                  (validOutput.commissions.hebergement * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Commissions sur location d'automobiles (Prière d'exculre les
                commissions sur des locations faisant faisant partie de
                voyages organisés ou de croisières)
              </th>
              <td>{{ validOutput.commissions.locationAutomobile }}</td>
              <td v-if="validOutput.commissions.locationAutomobile > 0">
                {{
                  (validOutput.commissions.locationAutomobile * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Commission sur primes d'assurances (telles que assurance
                voyage, assurance sur les bagages et assurance-annulation,
                etc)
              </th>
              <td>{{ validOutput.commissions.primesAssurance }}</td>
              <td v-if="validOutput.commissions.primesAssurance > 0">
                {{
                  (validOutput.commissions.primesAssurance * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <tr>
              <th scope="row">
                Autres Commisions (telles que les attractions, les chèques de
                voyage, etc) Veuillez préciser
              </th>
              <td>{{ validOutput.commissions.autresCommissions }}</td>
              <td v-if="validOutput.commissions.autresCommissions > 0">
                {{
                  (validOutput.commissions.autresCommissions * 100) /
                    (validOutput.commissions.billetterie +
                      validOutput.commissions.voyagesOrganises +
                      validOutput.commissions.croisieresToutCompris +
                      validOutput.commissions.hebergement +
                      validOutput.commissions.locationAutomobile +
                      validOutput.commissions.primesAssurance +
                      validOutput.commissions.autresCommissions)
                }}
                %
              </td>
              <td v-else>
                0
              </td>
            </tr>
            <!-- total -->
            <tr>
              <th scope="row">
                Recettes Brutes Totales
              </th>
              <td>
                {{
                  validOutput.commissions.billetterie +
                    validOutput.commissions.voyagesOrganises +
                    validOutput.commissions.croisieresToutCompris +
                    validOutput.commissions.hebergement +
                    validOutput.commissions.locationAutomobile +
                    validOutput.commissions.primesAssurance +
                    validOutput.commissions.autresCommissions
                }}
              </td>
              <td
                v-if="
                  validOutput.commissions.billetterie > 0 ||
                    validOutput.commissions.voyagesOrganises > 0 ||
                    validOutput.commissions.croisieresToutCompris > 0 ||
                    validOutput.commissions.hebergement > 0 ||
                    validOutput.commissions.locationAutomobile > 0 ||
                    validOutput.commissions.primesAssurance > 0 ||
                    validOutput.commissions.autresCommissions
                "
              >
                100 %
              </td>
              <td v-else>
                0 %
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Spacer -->
      <hr class="invoice-spacing mt-5">
      <div>
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            Charge salariale: <strong>{{ validOutput.chargesSalariales }} FCFA</strong>
          </b-col>
          <b-col
            md="6"
            class="mb-1 text-right"
          >
            Autres dépenses: <strong> {{ validOutput.autresDepense }} FCFA</strong>
          </b-col>
        </b-row>
      </div>
    </b-card-body>

    <!-- Spacer -->
    <!-- <hr class="invoice-spacing"> -->

    <!-- Note -->
    <!-- <b-card-body class="invoice-padding pt-0">
          <span class="font-weight-bold">Note: </span>
          <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
            projects. Thank You!</span>
        </b-card-body> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  data() {
    return {
    }
  },

  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background{
  background-color: #ababab;
}

.tableHeadFontSize{
  font-size: 10px;
}
</style>
